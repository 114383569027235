<template>
  <div>
    <div :class="[withAnimation ? 'content__animation-2 _hidden' : null ]"
         v-observe-visibility="$root.visibilityChanged">
      <form action="" ref="form" class="form consult-form__form" @submit.prevent="submit">
        <div class="form__row">
          <input type="text" class="form__textinput" name="name" placeholder="ФИО"
                 :class="{ 'form__textinput_error': $v.form.name.$error }" v-model="form.name">
        </div>
        <div class="form__row form__row_flex">
          <input type="text" class="form__textinput" name="phone" v-mask="'+7 ### ### ## ##'" placeholder="Ваш телефон"
                 :class="{ 'form__textinput_error': $v.form.phone.$error }" v-model="form.phone">
        </div>
        <div class="form__row">
          <div class="form__wrapper" v-mousedown-outside="cityBlur">
            <input
                type="text"
                name="city"
                autocomplete="suppress-autocomplete"
                placeholder="Город"
                class="form__textinput"
                :class="{ 'form__textinput_error': $v.form.city.$error || isCityError }"
                v-model="form.city"
                @input="getCityHandlerEvent($event)"
                @focus="cityFocused = true"
                @keydown="citySuggestionKeyDown"
            >
            <ul v-if="cityFocused && citySuggestion" class="form__suggestion">
              <li
                  v-for="(item, i) in citySuggestion"
                  :key="i"
                  :class="{ '_active': i === citySuggestionIndex }"
                  @click="setCity(item)"
              >{{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form__row form__row_flex">
          <div class="form__col">
            <label class="form__checkbox" :class="{ 'form__checkbox_error': $v.form.agree.$error }">
              <input type="checkbox" v-model="form.agree" @change="$v.form.agree.$touch()">
              <span class="form__checkbox-box"/>
              <span class="form__checkbox-label">Я согласен с <a href="/upload/processing_conditions.pdf"
                                                                 target="_blank">условиями обработки данных</a></span>
            </label>
          </div>
          <div class="form__col">
            <input class="btn btn_pink" type="submit" value="Получить консультацию">
          </div>
        </div>
      </form>
    </div>
    <div ref="popup" class="popup" v-if="popupVisible">
      <div class="popup__box" v-click-outside="outsideClose">
        <span class="popup__close" @click="close"></span>
        <div class="popup__errors" v-if="$v.$error">
          <p>Пожалуйста, заполните обязательные поля</p>
          <p v-if="$v.form.phone.$error">Пожалуйста, укажите корректный телефон</p>
          <p v-if="$v.form.agree.$error">Необходимо ваше согласие на обработку персональных данных</p>
          <p v-if="$v.form.city.$error">Неправильно заполнен город</p>
        </div>
        <div class="popup__success" v-else>
          <p class="popup__title">Спасибо за обращение!</p>
          <p>Сотрудник Церезит свяжется с Вами в ближайшее время</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import throttle from "../utils/throttle";
import {reachGoal} from "../utils/ymStats";
import {tagGA} from "../utils/gaStats";
import {adriverSendMetric} from "../utils/adriver";

export default {
  name: 'ConsultForm',
  props: {
    //communicationTypes: { type: Array, required: true },
    //requestThemes: { type: Array, required: true },
    ymId: {type: String},
    gaId: {type: String},
    adriverId: {type: String},
    adriverIdInput: {type: String},
    withAnimation: {type: Boolean, default: false}
  },
  data() {
    return {
      form: {
        name: '',
        // email: '',
        phone: '',
        agree: false,
        city: '',
        region: '',
        //file: '',
        //request: '',
        //question: '',
        //communicationType: this.communicationTypes[0].ID,
      },
      isCityState: false,
      isCityError: false,
      cityFocused: false,
      citySuggestion: [],
      citySuggestionIndex: -1,
      popupVisible: false,
      isStateChanged: false
    }
  },
  watch: {
    form: {
      handler() {
        if (!this.isStateChanged) {
          adriverSendMetric(this.adriverIdInput);
        }
        this.isStateChanged = true
      },
      deep: true,
    },
  },
  validations: {
    form: {
      name: {required, min: minLength(3)},
      //email: { required, email },
      phone: {required, min: minLength(16)},
      agree: {sameAs: sameAs(() => true)},
      city: {required},
      // region: { required },
      //request: { required },
      //question: { required },
      // communicationType: { },
    },
  },
  methods: {
    async submit() {
      const vm = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        vm.popupVisible = true;
        setTimeout(function () {
          vm.popupVisible = false;
        }, 4000);
      } else {
        try {
          if (!this.isCityState) {
            this.isCityError = true;
            this.form.city = "";
            return;
          }
          this.isCityError = false;
          const response = await axios({
            url: '/local/ajax/form.php',
            method: 'POST',
            data: this.getFormData(),
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.data.status === 'error') {
            throw new Error(response.data.errors);
          }
          reachGoal(this.ymId);
          tagGA(this.gaId);
          adriverSendMetric(this.adriverId)
          this.resetForm();
          vm.popupVisible = true;
          setTimeout(function () {
            vm.popupVisible = false;
          }, 4000);
          this.isCityState = false;
        } catch (e) {
          console.log('Error!', e);
          alert('Ошибка!');
        }
      }
    },
    getFormData() {
      const form = new FormData;
      //const fileInput = document.querySelector('#file');

      //form.append('file', fileInput.files[0]);
      form.append('name', this.form.name);
      //form.append('email', this.form.email);
      form.append('phone', this.form.phone);
      form.append('city', this.form.city);
      form.append('region', this.form.region);
      //form.append('request', this.form.request);
      //form.append('question', this.form.question);
      //form.append('communicationType', this.form.communicationType);

      return form;
    },
    resetForm() {
      this.$v.$reset();
      this.form.name = "";
      //this.form.email = "";
      this.form.phone = "";
      this.form.agree = "";
      this.form.city = "";
      this.form.region = "";
      // this.form.request = "";
      //this.form.question = "";
      //this.form.communicationType = "";
    },
    corsRequest(params) {
      var script = document.createElement('script'), src = '//kladr-api.ru/api.php?',
          res,
          rej,
          result = new Promise(function (resolve, reject) {
            res = resolve;
            rej = reject;
          });

      script.type = 'text/javascript';
      script.onerror = function () {
        script.parentNode.removeChild(script);
        rej();
      };
      script.onload = function () {
        script.parentNode.removeChild(script);
      };

      if (typeof params == 'string') {
        src += ['?', '&'].indexOf(params.substr(0, 1)) > -1 ? params.substr(1) : params;
      } else if (typeof params == 'object') {
        src += Object.keys(params).map(function (key) {
          return key + '=' + encodeURIComponent(params[key]);
        }).join('&');
      }

      var callback = 'corsCallback';
      window[callback] = function (json) {
        res(json);
        delete window[callback];
      };

      script.src = src + (!params ? '' : '&') + 'callback=' + callback;
      document.body.appendChild(script);

      return result;
    },
    getCityHandlerEvent(event) {
      this.form.city = event.target.value.replace(/[^а-яё]/ig, '');
      this.getCity(event.target.value);
    },
    getCity: throttle(function (val) {
      const vm = this;
      if (val.trim().length >= 3) {
        this.corsRequest(`?query=${val}&contentType=city&withParent=true`).then(function ({result}) {
          result = result.filter(function (item, index) {
            return index >= 1 && index <= 20
          });
          const filteredResult = result.map(function (item) {
            return item.name;
          });

          vm.citySuggestion = filteredResult.map(function (item, index) {
            if (
                (filteredResult.indexOf(item) !== index || filteredResult.slice(index + 1).indexOf(item) > -1)
                && result[index] !== undefined
            ) {
              return {
                label: `${item} (${result[index].parents[0].name} ${result[index].parents[0].type})`,
                response: result[index]
              }
            } else {
              return {
                label: item,
                response: result[index]
              }
            }
          });
        });
      } else {
        vm.citySuggestion = [];
      }
    }, 1000),

    setCity(value) {
      this.isCityState = true;
      this.form.city = value.label;
      if (value.response.parents.length) {
        this.form.region = value.response.parents[0].ifnsfl
      } else {
        this.form.region = value.response.ifnsfl
      }
      this.citySuggestion = [];
      this.cityBlur();
    },

    cityBlur() {
      this.cityFocused = false;
    },

    citySuggestionKeyDown(e) {
      if (!this.cityFocused) {
        return false;
      }

      if (!this.citySuggestion.length) {
        return false;
      }

      if (e.key === 'ArrowUp') {
        e.stopPropagation();
        e.preventDefault();
        if (this.citySuggestionIndex > 0) {
          this.citySuggestionIndex--;
        } else {
          this.citySuggestionIndex = this.citySuggestion.length - 1;
        }
      }

      if (e.key === 'ArrowDown') {
        e.stopPropagation();
        e.preventDefault();
        if ((this.citySuggestionIndex + 1) < this.citySuggestion.length) {
          this.citySuggestionIndex++;
        } else {
          this.citySuggestionIndex = 0;
        }
      }

      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        this.setCity(this.citySuggestion[this.citySuggestionIndex]);
        this.citySuggestionIndex = -1;
      }
    },
    outsideClose(e) {
      if (e.target === this.$el) {
        this.close();
      }
    },
    close() {
      this.popupVisible = false;
    },
  },
}
</script>
